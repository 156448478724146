<template>
  <footer>
    <div class="container">
      <img alt="logo" src="~/mlearn/icons/footer/vilearn-footer.svg" class="logo pointer" @click="goToPage('/chuyen-de')">
      <div class="row">
        <div class="col-lg-9 col-xl-5">
          <div class="company-info">
            <div class="name">Công ty chủ quản: Công ty Cổ phần Công nghệ và Dịch vụ viễn thông Mặt trời (Suntech Media)</div>
            <div><span>Địa chỉ: </span>Nhà số 16 - Lô TT29 Khu đô thị Văn Phú, - Phường Phú La - Quận Hà Đông - Hà Nội</div>
            <div><span>Giấy chứng nhận ĐKKD: </span>0108845940 do Sở KHĐT thành phố Hà Nội<br>Cấp lần đầu ngày 30/07/2019</div>
            <div><span>ĐKKD: </span> 0108845940 do Sở KHĐT thành phố Hà Nội <br>Cấp lần đầu ngày 30/07/2019</div>
            <!--            <div><span>Giấy chứng nhận đăng ký cung cấp dịch vụ nội dung, thông tin trên mạng viễn thông di động: </span>số 247/GCN-DĐ do Cục PTTH&TTĐT<br>Cấp ngày 30/12/2019</div>-->
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-3 line-mobile">
          <div class="block-data">
            <div class="title">THÔNG TIN</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <nuxt-link to="/thong-tin-khac/gioi-thieu">Giới thiệu</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac/dieu-khoan-su-dung">Điều khoản sử dụng</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac/chinh-sach-bao-mat">Chính sách bảo vệ dữ liệu cá nhân</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac/chinh-sach-thanh-toan">Chính sách thanh toán</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac/bao-hanh-va-quan-ly-khieu-nai">Bảo hành và quản lý khiếu nại</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-2 line-mobile">
          <div class="block-data">
            <div class="title">DỊCH VỤ CUNG CẤP</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <nuxt-link to="/">Hỏi bài</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/chuyen-de">Chuyên đề</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/tai-lieu">Tài liệu</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/trac-nghiem">Bộ đề</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 order-lg-last col-xl-2 line-mobile">
          <div class="block-data">
            <div class="title">HỖ TRỢ KHÁCH HÀNG</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <img alt="call" src="~/mlearn/icons/footer/call.svg" class="call"> 0243.686.5555
              </li>
              <li class="item email">
                <img alt="call" src="~/mlearn/icons/footer/email-white.svg" class="call"> yennh@suntechmedia.vn
              </li>
            </ul>
          </div>
          <div class="block-data d-none d-xl-block">
            <div class="title">TẢI ỨNG DỤNG VILEARN</div>
            <ul class="list-item list-unstyled">
              <li class="item form-data">
                <img alt="qrcode" src="~/mlearn/icons/footer/qrcode.png" class="qrcode">
                <div class="content platform">
                  <div>
                    <img alt="chplay" src="~/mlearn/icons/footer/chplay.svg" class="chplay w-100">
                  </div>
                  <div class="pt-1">
                    <img alt="appstore" src="~/mlearn/icons/footer/appstore.svg" class="appstore w-100">
                  </div>
                </div>
              </li>
              <li class="item note">Quét mã QR code để cài đặt</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-2 d-block d-xl-none line-mobile">
          <div class="block-data">
            <div class="title">TẢI ỨNG DỤNG VILEARN</div>
            <ul class="list-item list-unstyled mb-0">
              <li class="item form-data">
                <img alt="qrcode" src="~/mlearn/icons/footer/qrcode.svg" class="qrcode">
                <div class="content platform">
                  <div>
                    <img alt="chplay" src="~/mlearn/icons/footer/chplay.svg" class="chplay">
                  </div>
                  <div class="pt-2">
                    <img alt="appstore" src="~/mlearn/icons/footer/appstore.svg" class="appstore">
                  </div>
                </div>
                <div class="d-block d-lg-none align-self-end"><img alt="notify" src="~/mlearn/icons/footer/notify.svg" class="notify"></div>
              </li>
              <li class="item note">Quét mã QR code để cài đặt</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="copy-right"><img alt="copy-right" src="~/mlearn/icons/footer/copy-right.svg">2023 Bản quyền thuộc về: Suntech Media</div>
    </div>
    <template v-if="!user">
      <ModalLogin/>
      <ModalRegister/>
      <ModalForget/>
    </template>
    <!--    <ModalVerifyInstallPwa/>-->
    <ModalQuestion/>
    <ModalRedirectSub/>
    <ModalVerifyGuru v-if="!isVerifiedMobile"/>
    <ModalVerifyEmail/>
    <ModalVerifyClass/>
    <ModalNoMoreQuestion/>
    <ModalRequestSub/>
    <BackToTop :right="isMobile ? '10%' : '106px'"/>
  </footer>
</template>

<script>
import ModalForget from '~/components/mlearn/modal/forget.vue'
import ModalLogin from '~/components/mlearn/modal/login.vue'
import ModalRegister from '~/components/mlearn/modal/register.vue'
import ModalQuestion from '~/components/mlearn/modal/add-question.vue'
import ModalRedirectSub from '~/components/mlearn/modal/sub.vue'
import ModalVerifyGuru from '~/components/mlearn/modal/verify-guru.vue'
// import ModalVerifyInstallPwa from '~/components/mlearn/modal/install-pwa'
import ModalVerifyEmail from '~/components/mlearn/modal/verify-email.vue'
import ModalVerifyClass from '~/components/mlearn/modal/verify-class.vue'
import ModalNoMoreQuestion from '~/components/ai/ModalNoMoreQuestion.vue'
import ModalRequestSub from '~/components/ai/ModalRequestSub.vue'

export default {
  components: {
    ModalRequestSub,
    ModalNoMoreQuestion,
    ModalLogin,
    ModalRegister,
    ModalForget,
    ModalQuestion,
    ModalRedirectSub,
    // ModalVerifyInstallPwa,
    ModalVerifyEmail,
    ModalVerifyGuru,
    ModalVerifyClass
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isMobile () {
      return this.$store.state.isMobile
    },
    isVerifiedMobile () {
      return this.$store.state.isVerifiedMobile
    }
  },
  methods: {
    goToPage (path = '/') {
      this.$router.push({path})
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background: #1f7ed0;
  color: rgba(255, 255, 255, 0.8);
  padding: 28px 0 15px;
  @media (max-width: 576px) {
    padding: 15px 0 10px;
  }

  .logo {
    max-height: 42px;
    @media (max-width: 576px) {
      max-height: 36px;
    }
  }

  .row {
    .line-mobile {
      @media (max-width: 576px) {
        border-top: 1px solid rgba(255, 255, 255, .2);
      }
      // @media (max-width:991px) {
      //   border-top: 1px solid rgba(255, 255, 255, .2);
      // }
    }

    > [class*="col-"] {
      padding-top: 2rem;
      @media (max-width: 576px) {
        padding-top: 1rem;
      }

      .company-info {
        padding-right: 50px;
        @media (max-width: 1366px) {
          padding-right: unset;
        }
        @media (max-width: 576px) {
          padding-right: unset;
        }

        > div {
          color: rgba(255, 255, 255, .6);
          font-size: 14px;
          white-space: break-spaces;
          margin-bottom: 10px;
          @media (max-width: 576px) {
            margin-bottom: 8px;
          }

          &.name {
            font-weight: bold;
            color: rgba(255, 255, 255, 0.8);
            font-size: 16px;
            @media (max-width: 576px) {
              font-size: 14px;
            }
          }

          span {
            font-weight: bold;
            color: rgba(255, 255, 255, .8);
            @media (max-width: 576px) {
            }
          }

          .notify {
            max-height: 60px;
            @media (max-width: 576px) {
              max-height: 40px;
            }
          }
        }
      }

      .block-data {
        margin-top: 35px;
        @media (max-width: 576px) {
          margin-top: 25px;
        }

        &:first-child {
          margin-top: 0;
          @media (max-width: 576px) {
          }
        }

        .title {
          font-weight: bold;
          color: rgba(255, 255, 255, .6);
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 18px;
          @media (max-width: 576px) {
            margin-bottom: 10px;
          }
        }

        .list-item {
          margin-bottom: 0;
          @media (max-width: 576px) {
            white-space: nowrap;
          }

          .item {
            font-weight: 500;
            font-size: 14px;
            color: rgba(255, 255, 255, .8);
            margin-bottom: 8px;
            @media (max-width: 576px) {
              display: inline-block;
              margin-right: 12px;
              &:last-child {
                margin-right: 0;
              }
            }

            &:last-child {
              // margin-bottom: 0;
              @media (max-width: 576px) {
              }
            }

            &.note {
              font-style: italic;
              font-weight: normal;
              font-size: 12px;
              color: rgba(255, 255, 255, .6);
              @media (max-width: 576px) {
              }
            }

            a {
              font-weight: 500;
              font-size: 14px;
              color: rgba(255, 255, 255, .8);
            }

            .platform {
              margin-left: 10px;
              @media (max-width: 576px) {
              }

              img {
                margin-bottom: 10px;
                @media (max-width: 576px) {
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .notify {
              max-width: 120px;
            }
          }
        }
      }
    }
  }

  .line {
    height: 1px;
    margin: 12px 0;
    background: rgba(255, 255, 255, .2);
    @media (max-width: 576px) {
    }
  }

  .copy-right {
    font-size: 14px;
    color: rgba(255, 255, 255, .6);
    @media (max-width: 576px) {
    }
  }

  .email {
    width: 200px;
    position: absolute;
  }
}
</style>
